import React from "react";

const arjun_deodhar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Arjun Deodhar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>DE Shaw</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>
                        Before I begin sharing my experience and thoughts, I dedicate whatever I have been able to achieve till now in computer science; to my teacher, guide and mentor Prof. Abhijit A M.
                        <br />
                        <br />
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>

                            Online test/Aptitude Round - Taken on hacker-rank platform, contained aptitude, technical and coding questions (50 mins) <br />
                            Technical interview 1 (45 mins) <br />
                            Technical interview 2 (45 mins) <br />
                            HR interview (15 mins) <br />

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Eligibility criteria for applying for the internship:  Students from all E-group branches, minimum CGPA criteria declared by the company.<br />
                            (Minimum CGPA requirement was  different for every branch). <br />
                            For example, for CS, all those with a CGPA above 7.5 were eligible for the test.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The test was conducted on the hacker - rank platform. <br />
                            The format of the test was as follows : <br />
                            Aptitude based MCQ questions that are typically asked in an IQ test. Some of the questions were technical questions on Data structures, OOPs, OS. <br />
                            (Roughly 25 questions to be solved in 30 mins) <br />
                            Coding question (Freedom of language selection) <br />
                            There was only one coding question to be solved in 20 minutes, which was to pass some visible test cases, and many hidden test cases. <br />

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The online test was conducted on a Sunday evening. The results came in by midnight.
                            Technical interview 1 was scheduled at 9.00 am the next morning (Monday). All the interviews were completed on the same day.
                            <br />
                            <br />
                            When I got to know that I cleared the online test, at midnight. I was very excited and could not sleep the whole night. It was my first interview ever!
                            <br />
                            <br />
                            I had solved the coding question in an inefficient way, and asked my friend Avnish Chaudhary to explain the dynamic programming solution to me. The interviewers did ask me to solve the question again in both rounds the next day, and this discussion really helped.
                            <br />
                            <br />
                            Subject Knowledge - Absolute necessary
                            During the sleepless night, I prepared myself on what I want to showcase my knowledge of computer science - my passion. I prepared myself to showcase ideology, history, philosophy - every aspect about this subject.
                            <br />
                            <br />
                            I did not have to revise anything or read anything new about the subject, but I gathered my thoughts and summarised all that I wanted to showcase. I prepared myself to talk about everything I had learnt, read, and been taught in the past year and half. I structured it in a presentable way.
                            <br />
                            <br />
                            I also prepared myself for some soft skill questions, I thought an interviewer might ask me. For example,
                            Why do you think that this company should hire you?<br />
                            If you want to learn a new language, how will you do so?<br />
                            Why do you like computer science?<br />

                            I have some really strong areas which I wanted to make it a point to reach to the interviewer, one way or the other. I prepared some catchy sentences around those points like
                            <br />C (programming language) is my mother tongue, I think in C !
                            <br />It takes 10,000 hours to become a good programmer
                            <br />
                            <br />My approach to problem solving (Abhijit Sir taught me this) is to
                            <br />1. Understand real life
                            <br />2. Try to solve it by yourself (solve it on a piece of paper / on a board)
                            <br />3. Write the code (this becomes the easiest part when steps 1 and 2 are done properly)
                            <br />I love to read good textbooks, study from the documentation rather than watching youtube videos
                            <br />
                            <br />
                            After this preparation I was able to plug most of these points in different answers, and did not miss an opportunity to put them forward.
                            <br />
                            <br />
                            Not just bookish knowledge, but you should also be current with what’s happening in the world in your subject area. I prepared myself with some facts about the major incidents during those days. E.g I refreshed the facts and figures about the ‘blue screen of death’ attack/bug on microsoft machines.
                            They did ask about it :) and it helped that I was all prepared.

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Technical Interview 1: <br />
                        Focus of this round was on my projects. Interviewers asked questions about my DSA project, as well as my Computer Networks project. In short, it was a mix of me explaining my projects and then the interviewers asking me questions from theory topics related to those projects. I think I could clear this round because all the project work was hands on.  I knew design as well as implementation details of my work.
                        <br />
                        Technical Interview 2: <br />
                        Interviewers gave me real world scenarios that one might face (for example, the blue screen of death, or the specs of a processor) and checked my approach on those. Apart from that they also asked a lot of technical questions about database queries, computer networks, and even my solution to the coding question on the previous day’s test. They also asked me to solve a puzzle / riddle in the 2nd round.
                        <br />
                        HR Round: <br />
                        In the HR round interviewers asked questions about the decisions that I had made related to projects, or choice of selecting a particular club in college, my hobbies etc. Questions were also asked related to time management, group dynamics in projects etc.


                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        The topics would be Computer Networks, Data Structures and Algorithms, Databases.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Eligibility criteria for applying for the internship:  Students from all E-group branches, minimum CGPA criteria declared by the company. (Minimum CGPA requirement was  different for every branch).
                        For example, for CS, all those with a CGPA above 7.5 were eligible for the test.
                        <br />
                        Online aptitude test: Around 200+ students appeared for the test. 17 students cleared the aptitude test round and were shortlisted for the technical interview 1.
                        <br />Technical interview 1:  17 students appeared, 5 students were shortlisted.
                        <br />Technical interview 2: All 5 appeared, all selected and were sent to HR round.
                        <br />HR round: Out of 5, 1 student was selected for internship.
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        I am an old school when it comes to my method of studying. I like to use online content, but not as a replacement for books. Online content is supplementary to the core reference books. Throughout my FY and SY, I read a lot of good books at a great depth. Some topics or books I read twice or thrice. This helped me understand the subject at length and breadth. It's deep as well as it covers various aspects of the topic.
                        <br />
                        <br />
                        Studying came more out of passion or interest rather than to prepare for an internship, crack any interview, or to score well in college exams. That is not the main aim, it's a bi product.
                        (My CGPA was not the top most. It was 8.53, because I would score well in CS subjects, and not so much in the others).
                        <br />
                        <br />
                        Following is the list of books that I have thoroughly studied, will highly recommend to the students, these  are life long keepsakes.

                        <br />
                        <br />1. The UNIX Operating System, by Sumitabha Das
                        (it isn’t an OS book, it is about UNIX commands and the philosophy behind designing UNIX)
                        <br />2. The C Programming Language, by Brian Kernighan and Dennis Ritchie
                        <br />3. How to Solve It by Computer, by R G Dromey
                        <br />4. Programming Languages, by Terrence Pratt (For PPL)
                        <br />5. Discrete Mathematics, by Kenneth Rosen (I liked Liu’s book “the elements of discrete mathematics” more than Rosen’s, but I found out about that book later, and I am currently studying from it :) )
                        <br />6. Introduction to the theory of computation, by Miechal Sipser (For TOC)
                        <br />7. Automata Theory, Languages and Computation, by Ullman (For TOC)
                        Note: this one is recommended for TOC, it is much better than Sipser’s book
                        <br />8. Database System Concepts, by Abraham Silberschatz (For DBMS)
                        <br />9. Computer Networking, A Top-Down Approach, by Jim Kurose and Keith Ross (For CN)
                        <br />10. Computer Organization, by Carl Hamacher and Safwat Zaky (For CO)
                        <br />
                        <br />
                        I watched lectures, and sometimes they are required. I was really lucky to have access to Abhijit Sir’s online lectures for DSA and CN. I learnt Art of Programming with C Programming language as a medium from him from his classroom lectures. I always admire his teaching in class and online lectures. He nicely delivers the course content and philosophy behind the concept, which is a rare find, I liked it.
                        <br />
                        <br />
                        I would strongly recommend these kinds of online content over one shot series focusing just on clearing exams, and not on gaining knowledge.
                        <br />
                        <br />
                        A few other things which helped me go closer to the subject are:
                        <br />1. Use vi editor for all my work over VS Code. This clears up your concepts of programming  at early stages of your programming career. Your thoughts will be aligned nicely.
                        <br />2. Use Ubuntu over Windows. Most of my work is done using the terminal and Linux commands. We are technocrats, we should be learnt, adapt to the OS for its architecture over convenience of use.
                        <br />3. As far as possible, I prefer low level programming as a language of choice for my projects.
                        <br />4. I refrain from using AIs like chatgpt etc for learning, debugging, and finding solutions. These tools are to replace you as a programmer, not to help you or teach you. Use these productivity tools for cosmetic things like drafting this document :) for example.
                        <br />5. I give extra credit to my group who worked really hard on our DSA project in the 4th semester.  As a team with me, Arnav Prasad and Prajwal Bhosale worked on Knowledge Graphs. It played a vital, pivotal role in improving my coding abilities as well as DSA knowledge.
                        <br />6. I like to get inspired by people who have shaped up this industry and contributed immensely. I listen to talks and interviews of some of these great personalities in the field. To name a few important ones, Linus Torvalds, Ken Thompson, Richard Stallman, Donald Knuth…
                        <br />
                        <br />
                        To sum up, I think I was and I am enjoying my course to the fullest and an internship just happened to come my way. I am preparing to become a good programmer, and that’s my only goal !

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Focus more on understanding the basic concepts thoroughly.
                            <br />Read a lot of good textbooks and work on good projects that develop your coding skills.

                        </p>
                    </p>
                    <br />
                    {/* <p>
                        <b> 7. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            For me, almost 80% of the interview was based upon my projects. The interviewer also seemed impressed by my resume so I just played my cards right and presented my values and skills by highlighting my experiences, clubs, extracurricular activities and achievements.

                        </p>
                    </p> */}
                    <br />
                    <p>
                        <b> 10. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            This is my only experience with internship interviews. So I can talk about something in general not specific to DE Shaw.
                            <br /> It is stressful, physically and mentally. A lot of pressure, as the time proceeds.
                            <br />
                            You need to keep your cool for success. During the entire process, I thank classical music and harmonium.
                            <br /> While strolling around outside the interview room, I was singing one of my favorite compositions and doing “vistaar” (musical term) of the composition. This really helped me stay cool during the tiring interview process, which might have helped a lot.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Be yourself !
                            <br/>
                            All the best :) You will do good.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:abhinavk21.comp@coeptech.ac.in">
                        arjundeodhar@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default arjun_deodhar;
