import React from "react";

const arya_wankhede = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Arya Wankhede
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            The first round was resume shortlisting of students for the aptitude test. These students attended the online PPT for Hilti, after which the aptitude test was conducted.
                            <br /> This was followed by a technical interview round, and then lastly, an HR interview round.

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            In the initial stages, candidates were shortlisted for the aptitude test based on a pointer criteria, and their resumes.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude test was conducted in online mode.
                            <br />The total duration was 90 minutes, with questions consisting of logical reasoning and aptitude, and those focusing on core subjects - DSA, DBMS, CN, OS. There was one DSA coding question.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The list of shortlisted students was announced the day before the interviews were to be conducted.
                            <br />During this time, I made sure I was very thorough with each and every sentence in my resume, and I tried to come up with questions that could be asked about my resume by the interviewer.
                            <br /> I also reviewed past mistakes, or fumbles that I had made in a previous interview and made sure to work on those.
                            <br /> Lastly, I made sure to research Hilti and what it does. It is always good to know as much as you can about the company you are interviewing for!


                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 rounds in total for Hilti’s hiring process. The first round was the online aptitude test. The second round was the technical interview, and the last round was the HR interview.


                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        During the technical interview, I was asked questions about the projects in my resume, about the overview, and what else I could add on to it.
                        <br /> So make sure you are thorough with the components of your projects, and are also aware of which areas to improve on.
                        <br />I was asked behavioural questions as well, and also given questions on hypothetical situations in the workplace.

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Initially, 233 students were shortlisted based on their resumes for the aptitude test. After the test, 25 students were shortlisted for the technical interview.
                        <br />Finally, 7 students were selected for the HR round, out of which 4 of us were selected as interns.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        For DSA coding questions, I used leetcode and GFG, and in the weeks leading up to the beginning of the internship process, I solved most questions from the “Striver’s SDE Sheet – Top Coding Interview Problems”.
                        <br /> Be sure to solve as many questions as you can, and make sure you focus on topics like arrays, binary trees, linked lists.

                        <br />For concepts like DBMS, OS, CN, I referred to youtube videos, as well as GFG articles on the topics.

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Do not be stubborn when it comes to looking at solutions to questions you are not able to solve. I learnt this the hard way after having wasted hours just on 1-2 questions.
                            <br />Give yourself 15-20 minutes for easy questions, 30 minutes for medium questions and 45-60 minutes for hard level questions. After this time if you are still at a loss, look for hints and try to attempt the problem. If you still cannot solve the question, look at the approach of the solution, and try to do it on your own. Finally, if you are unable to solve it, look at the solution code, and mark the question so you can revisit it in a couple of days.
                            <br />It gets easier guys!

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I was asked plenty of questions about my projects, and the extracurriculars on my resume.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Recruiters for Hilti will look at your technical skills, but also keep in mind that they pay attention to your personality, and see how you might fit into their workplace. Be confident during your interview, if you need time to think about a question, politely ask for it, instead of blurting out something incorrect.
                            <br />It is good to know about the company you are applying for, so make sure to attend the PPT and do your own research about Hilti.
                            <br /> At the end of the interview, be sure to ask questions, if you are given the opportunity.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The internship process can be long and tiring but don’t let it take a toll on you.
                            <br />If you are not selected for a company, it is OK, you have to move on fast, and prepare for the next ;) Don’t be disheartened and be sure to make a note of the mistakes you made in your interview, so you won’t repeat them again. Lastly, be confident in your interviews and carry yourself with grace! Best of luck!

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:wankhedeam22.comp@coeptech.ac.in">
                        wankhedeam22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default arya_wankhede;
