import React from "react";

const anshul_shelokar = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Anshul Shelokar
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>WellsFargo</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							Structure of Entire Interview Process : There were in total 4
							rounds-
							<br />
							1) Online Aptitude
							<br />
							2) Technical Interview
							<br />
							3) Senior Managerial Round <br />
							4) HR Interview
						</p>
					</p>
					<p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
							In the initial stages of the recruitment process, Wells Fargo set
							a minimum CGPA criterion of 7.5 to shortlist students for the
							Online Aptitude Test.
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							This round consisted of four sections :
							<ol>
								<li>
									English
									<ul>
										<li>
											Questions: Synonyms, antonyms, error detection, correct
											spelling, and fill-in-the-blanks.
										</li>
										<li>
											Tip: Practice similar questions on platforms like
											IndiaBix.
										</li>
									</ul>
								</li>
								<li>
									Business-Related
									<ul>
										<li>
											Questions were based on interpreting data from graphs, pie
											charts, or histograms related to business scenarios.
										</li>
									</ul>
								</li>
								<li>
									Coding Section
									<ul>
										<li>
											Two coding problems, ranging from easy to medium
											difficulty.
										</li>
										<li>
											Topics: Concepts like two-pointer approach and binary
											search.
										</li>
									</ul>
								</li>
								<li>
									Critical Thinking
									<ul>
										<li>A graph-based question of hard difficulty.</li>
										<li>
											Format: This section included three sets of problems, with
											each student randomly assigned one.
										</li>
									</ul>
								</li>
							</ol>
							{/* Uncomment following if list componenets are causing problems*/}
							{/* 
                        1. English<br />
                            ◦ Questions: Synonyms, antonyms, error detection, correct spelling, and fill-in-the-blanks.<br />
                            ◦ Tip: Practice similar questions on platforms like IndiaBix.
                        2. Business-Related <br />
                            ◦ Questions were based on interpreting data from graphs, pie charts, or histograms related to business scenarios.
                        3. Coding Section <br />
                            ◦ Two coding problems, ranging from easy to medium difficulty. <br />
                            ◦ Topics: Concepts like two-pointer approach and binary search. <br />
                        4. Critical Thinking (10 minutes) <br />
                            ◦ A graph-based question of hard difficulty. <br />
                            ◦ Format: This section included three sets of problems, with each student randomly assigned one. <br />   
                        */}
						</p>
					</p>
					<p>
						<b>
							4. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							After the Online Aptitude Test, the results were declared within 2
							days, and the interviews were scheduled for the very next day,
							making it a total of 3 days from the OA to the interviews. During
							this period, I concentrated on revising key topics like <b>DSA</b>{" "}
							and <b>DBMS</b>. Additionally, I brushed up on important concepts
							from <b>Networking, Operating Systems,</b> and <b>Core Java</b>
							.(Refer InterviewBit)
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							5. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
						<p>
							<strong>Round 1 (Online Aptitude)</strong>
							<br />
							<ol >
								<li>
									English
									<ul>
										<li>
											Questions: Synonyms, antonyms, error detection, correct
											spelling, and fill-in-the-blanks.
										</li>
										<li>
											Tip: Practice similar questions on platforms like
											IndiaBix.
										</li>
									</ul>
								</li>
								<li>
									Business-Related
									<ul>
										<li>
											Questions were based on interpreting data from graphs, pie
											charts, or histograms related to business scenarios.
										</li>
									</ul>
								</li>
								<li>
									Coding Section
									<ul>
										<li>
											Two coding problems, ranging from easy to medium
											difficulty.
										</li>
										<li>
											Topics: Concepts like two-pointer approach and binary
											search.
										</li>
									</ul>
								</li>
								<li>
									Critical Thinking
									<ul>
										<li>A graph-based question of hard difficulty.</li>
										<li>
											Format: This section included three sets of problems, with
											each student randomly assigned one.
										</li>
									</ul>
								</li>
							</ol>
							<br />
							<strong>Round 2 (Technical Interview)</strong>
							<br />
							This round was conducted virtually with a single interviewer. It
							began with a personal introduction and a discussion about the tech
							stacks I was familiar with, followed by basic questions about
							cloud platforms. The conversation then shifted to my projects,
							where the interviewer asked in-depth questions about the
							technologies I used and my decision-making processes. Sorting
							algorithms were a key topic, with discussions around the
							best-suited algorithm for large datasets, its time complexity, and
							alternative methods. Additionally, there were two DSA questions,
							but no coding was expected—only an explanation of the approach.
							Questions were-
							<br />
							1) a.Reversing a string/sentence b.Reversing a string/sentence
							without reversing the word (follow up qs)
							<br />
							2) a.Given a paragraph, count the frequency of a given word in it.
							b.Given a paragraph, give the maximum frequency of a given word in
							a single line.(follow up qs)
							<br />
							<br />
							<strong>Round 3 (Senior Managerial Round)</strong>
							<br />
							It was a mix of Technical and HR round. The interviewer delved
							deep into my projects, questioning why I chose the MERN stack, the
							advantages of MongoDB over SQL, and how I ensured data security,
							such as protecting student data from administrators. Other
							technical topics included SSL, multithreading, memory management
							in Java, daemon threads, and comparisons like arrays versus
							vectors and heap versus stack memory. The conversation also
							touched on broader subjects like no-code/low-code. On the HR side,
							there were questions about teamwork, temperament during projects,
							and even family-related discussions to understand my background
							better.
							<br />
							<br />
							
							<strong>Round 4 (HR Interview)</strong>
							<br />
							The HR manager was enthusiastic and started with general topics
							like my college and past companies that had visited the campus.
							The questions were designed to assess my personality, including my
							strengths, failures, and significant successes not mentioned in my
							CV. There were also scenario-based questions, such as how I
							handled team communication during projects, what I would do if
							faced with an unsolvable problem, and how I would attract more
							students to apply to Wells Fargo as a campus brand ambassador.
						</p>
					</p>
					<br />
					<p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
						<p>
							<b>Technical Interview</b>:- It was more focused on DSA, OOPS and
							DBMS.
							<br />
							<b>Senior Managerial Interview</b>:- This round delved into the
							strategies and decisions made while working on projects,
							emphasizing: <br />
							1) Approaches taken for project development
							<br />
							2) Understanding of the technologies used
							<br />
							3) Problem-solving mindset and temperament during the project
							lifecycle Topics - Projects,DBMS,OOPS,Java,Networks and behavioral
							questions(in decreasing order of significance)
							<br />
							<b>HR Interview</b>:- This final round assessed personal and
							professional compatibility with Wells Fargo. Many behavioural
							questions like strengths, successes and failures in life were
							asked. Questions were related to team collaboration skills,
							particularly my attitude and correspondence when working in a
							team.
						</p>
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round? </b>
						<br />
						After the Online Aptitude round, 22 students were shortlisted for
						the Technical Interview. From the Technical Interview, 13 students
						were selected for the Senior Managerial Round. All 13 students who
						passed the Senior Managerial Round were invited to the HR Interview.
						Finally, <b>11 students</b> received internship offers from Wells
						Fargo.
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep? </b>
						<br />
						Resources that I used for- <br />
						1) DSA-{" "}
						<a
							style={{ display: "inline" }}
							href="https://takeuforward.org/interviews/strivers-sde-sheet-top-coding-interview-problems/"
						>
							Striver SDE Sheet
						</a>{" "}
						<br />
						2) DBMS-{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.youtube.com/playlist?list=PLDzeHZWIZsTpukecmA2p5rhHM14bl2dHU"
						>
							CodeHelp DBMS Placement Series
						</a>{" "}
						|{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.w3schools.com/MySQL/default.asp"
						>
							w3Schools
						</a>{" "}
						<br />
						3) OS-{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.youtube.com/playlist?list=PLDzeHZWIZsTr3nwuTegHLa2qlI81QweYG"
						>
							CodeHelp OS Placement Series
						</a>{" "}
						|{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.geeksforgeeks.org/last-minute-notes-operating-systems/"
						>
							GeeksForGeeks
						</a>
						 (For quick revision) <br />
						4) Computer Networks- Computer Networking A Top-Down Approach,
						Kurose |{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.interviewbit.com/networking-interview-questions/"
						>
							InterviewBit
						</a>
						<br />
						5) Java-{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.youtube.com/playlist?list=PL9gnSGHSqcnr_DxHsP7AW9ftq0AtAyYqJ"
						>
							Kunal Kushwaha
						</a>{" "}
						|{" "}
						<a
							style={{ display: "inline" }}
							href="https://www.javatpoint.com/java-tutorial"
						>
							Javatpoint
						</a>
					</p>
					<br />
					<p>
						<b> 9. Some tips to crack coding questions ?</b>
						<br />
						<p>
							When an interviewer asks a coding question,start by asking
							clarifying questions if anything is unclear. Understand the
							problem statement fully and identify edge cases. This ensures you
							don’t miss important details.Think through the solution and
							outline your approach before jumping into the code. You can even
							explain your approach verbally to the interviewer.While coding,
							explain your thought process to the interviewer.Once the
							interviewer agrees on a basic solution, see if you can optimize it
							for better time or space complexity.To be able to solve coding
							questions during interviews or online aptitude, try to practice as
							much as you can on platforms like Leetcode, HackerRank, etc.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							10. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
							Throughout all three interview rounds, projects were the focal
							point of discussion. Commonly asked questions revolved around key
							decisions in your project, such as:
							<br />
							<ul>
								<li>Why did you choose this tech stack?</li>
								<li>What are the advantages of using it?</li>
								<li>Why did you prefer MongoDB over SQL?</li>
								<li>How did you design the database schemas?</li>
								<li>
									Which hosting platform did you use to deploy your web
									application?
								</li>
							</ul>
							{/* Uncomment following if list causes problems */}
							{/*
                        • Why did you choose this tech stack? <br />
                        • What are the advantages of using it? <br />
                        • Why did you prefer MongoDB over SQL? <br />
                        • How did you design the database schemas? <br />
                        • Which hosting platform did you use to deploy your web application? <br />
                        */}
							Additionally, involvement in extracurricular activities,
							especially clubs, was a recurring topic. For instance, I was
							consistently asked at least one question about the Software
							Development Section (SDS) in every interview round. These
							questions often tested my understanding and contributions to the
							club, making it essential to prepare well if you have similar
							experiences.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							11. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
							Clearing the Online Assessment (OA) is the first and most crucial
							step for Wells Fargo. To stand out, prioritize mastering
							business-related questions. Many candidates perform well in coding
							but struggle to tackle business-oriented problems with speed and
							accuracy. Excelling in this area can set you apart. <br />
							Once you clear the OA, subsequent rounds largely hinge on your
							resume. Therefore, craft your resume strategically, ensuring it
							accurately reflects your skills and experiences. Be prepared to
							answer in-depth questions about everything listed on it. <br />
							Additionally, before appearing for the HR round, conduct thorough
							research about the company. HR interviews often include
							company-specific questions, and demonstrating knowledge about
							Wells Fargo shows genuine interest. A great way to prepare is by
							actively attending the company’s pre-placement talk. This will
							provide valuable insights and help you present yourself as a
							well-informed and enthusiastic candidate.
						</p>
					</p>
					<br />
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>
							<i>
								Don’t get disheartened by rejections; something better is always
								waiting for you.
							</i>
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:shelokarau22.comp@coeptech.ac.in">
							shelokarau22.comp@coeptech.ac.in
						</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default anshul_shelokar;
