import React from "react";

const sarthak_pawar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sarthak Pawar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>UBS</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            One technical round and one HR round.
                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            The score in the aptitude test was used to screen the candidate.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        1 DSA question , few general aptitude questions and few about core computer science.
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                        I had to wait for 5-6 hours for my first interview. 
                        <br/>I revised some core CS like DBMS concepts because I had not prepared for it much before. 
                        <br/>I also revised common algorithms like searching, sorting, array and tree search , etc and their time and space complexities. 
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        The first round was a technical round where they asked about most things on my resume, mostly projects and then a leetcode easy and a
                        leetcode medium level DSA question. 
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        My interviewers were more focused on projects and DSA but some of the other panels asked logic puzzles, DBMS, etc. as well. 
                        <br/>There is no fixed format for an interview regarding the topics and subjects that were tested. 
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        27-28 after aptitude. About 15-20 people gave the HR round
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        Strivers SDE sheet for DSA prep if you are starting late, if you have the time, go through Striver's A to Z sheet 
                        <br/>Gate smashers, neso academy  and other youtube channels for core topics 
                        <br/>You should have a deep understanding of your project and all the technologies used in it.  

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Try to recognise what pattern would be used for the problem ( sliding window, search, dp, greedy, etc ) and then 
                        try modifying the standard solution to that pattern to solve the given problem .
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            The HR asked 1-2 questions but not much .
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Be confident during the interviews , the HR round was as long as the technical round and they asked a couple of questions about UBS whose answers were given in the pre-placement talk, so listen to the talk carefully.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        The internship selection process can be long, frustrating  and testing.Luck also plays some role. Do not lose patience and keep working consistently. 
                        </p>
                    </p>
                    <br />

                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:sarthakp1504@gmail.com">
                        sarthakp1504@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sarthak_pawar;