import React from "react";

const pratham_tandale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Pratham Tandale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>
                        <br />
                        <br />
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>

                            1 Aptitude Test + 4 Online Interview Rounds (~1hr each)

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Resume and projects are important. Academics is important as well.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            2 Coding Questions (Leetcode Medium) +
                            Technical Questions (DBMS, System, General CS, etc) +
                            General aptitude questions

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            Roughly a day.
                            <br />
                            Start early. Don’t create a last moment rush.


                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Structure of Interview Rounds :
                        <br />
                        Round 1: DSA Medium-Hard Problems + some OOPS concepts
                        (Practice question on platforms like Leetcode, etc.)
                        <br /><br />Round 2: DBMS, CS fundamentals, OOPS, language specific questions
                        (If not in-depth concepts knowledge, at least do basics properly)
                        <br /><br />Round 3: Projects, System Design, DSA Medium-Hard Problems
                        (Approach and thinking patterns were analysed, interviewer as experienced)
                        <br /><br />Round 4: HR [simple and basic questions]
                        (Never lie in HR round, keep a smiling face)

                    </p>
                    <br />
                    {/* <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        The topics would be Computer Networks, Data Structures and Algorithms, Databases.
                    </p> */}
                    <br />
                    <p>
                        <b>6. How many students were shortlisted after each round? </b>
                        <br />
                        Round 1: 15
                        <br />Round 2: ~ 7-8
                        <br />Round 3: ~ 3-4
                        <br />Round 4: 2

                    </p>
                    <br />
                    <p>
                        <b>7. Some good resources you used in your internship prep? </b>
                        <br />
                        Striver A-Z DSA Sheet 455, and Leetcode.
                    </p>
                    <br />
                    <p>
                        <b> 8. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            ‘Practice’ is the only prerequisite for cracking coding questions.
                            <br />Be consistent from day one, and maintain discipline.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 9. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Projects, clubs and extracurricular activities matters. It depends on how you drive the interviewer.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Keep a strong hold on DSA. Do good projects. Your attitude in the interview matters.
                            <br />Be polite and humble. Enjoy the process.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Learn to digest failures!
                            <br />It’s not the end of the world if you fail. Cry for an hour and then start again. Don’t let your failure affect your next performance.

                            <br /><br />All days are not the same!
                            <br />You might feel like not doing anything. Just push yourself a little, and do the smallest version of what you do daily.

                            <br /><br />Breaks are important!
                            <br />If you can solve 10 DSA question daily continuously for 30 days, then trust me, you need to consult a doctor 😯

                            <br /><br />Make yourself Resilient!
                            <br />Whatever breaks your streak, start again!

                            <br /><br />Resume is important!
                            <br />Invest time, keep it short and crisp, review it from seniors.

                            <br /><br />Do unique projects!
                            <br />Projects don’t stand out in interviews, the ideas behind them do.

                            <br /><br />Start already!
                            <br />If you haven’t, ‘now’ is the best time to start.

                            <br /><br />Think Loudly!
                            <br />Let the interviewer know what you are thinking, so utter all the words which you are thinking.

                            <br /><br />Enjoy the process!
                            <br />The more you enjoy the interview, the more likely it is that they will select you.

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:abhinavk21.comp@coeptech.ac.in">
                        prathamt12345@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default pratham_tandale;
