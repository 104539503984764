import React from "react";

const aveena_dange = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Aveena Dange
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            On declaration of the list of eligible candidates, the Company presentation was scheduled, followed by Online Aptitude Test.
                            Based on the results of the Aptitude test, the shortlist was received .

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            During the initial recruitment process, the short list was given to the students who met the CGPA criterion mandated by the company. This was followed by aptitude and test cases.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Aptitude round comprised of 4 sections viz, Numerical ability, Coding, and section on core subjects i.e. DSA, Computer Networking, DBMS, OS.
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            On declaration of the results of the aptitude round, the interviews were scheduled the very next day.
                            <br /> Since the time duration was limited, I revisited my resume, projects and also practiced DSA.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Based on the results of Aptitude round, the interview comprised of two parts, i.e. Technical and HR.
                        <br />Technical round had questions based on the subjects studied, projects and other application based questions. While HR round was situational analysis.

                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        The interviewers focused on the fundamentals of OOPS, DSA, DBMS.

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude round, 25 students were shortlisted. This was followed by a technical round.
                        <br />9 students were selected for the HR round and finally 4 received the Summer Placement offer.
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        <br />1. TUF sheets
                        <br />2. Leetcode
                        <br />3. Hackerrank
                        <br />4. Geeks for Geeks

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practise questions on multiple coding platforms. Be thorough with the concepts, don’t learn the codes.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            There were around 5-6 questions based on the projects completed in depth. This was followed by a few questions on the subjects.
                            <br /> Apart from this the co-curricular and extracurricular activities.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Know your projects completely, and related concepts like data structures used and their applications.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        My take will be to be thorough with the subjects and projects.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:aveenadange@gmail.com">
                        aveenadange@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default aveena_dange;
