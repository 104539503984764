import React from "react";

const aashay_inamdar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div className="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Aashay Inamdar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            Online Test, Virtual Interview (Technical), In-person Senior Manager Interview, HR Interview
                        </p>
                    </div>
                    <div>
                        <b>2. What was the structure of the Aptitude Round?</b>
                        <p>
                            The aptitude round had three sections. First section was based on English proficiency, with mostly easy and a few moderate difficulty level questions.
                            The second section was more Data Interpretation/Business Analysis related.
                            <br />Questions on the basis of interpretation of bar graphs were asked.
                            One highlight of this section was that there were a few questions on candlestick patterns.
                            <br />The third section had three coding questions, with the first two questions having more individual time than the last question.
                            The first two questions had about 20 minutes each while the last one had only 10 minutes to be solved.
                            <br />First question was basic, the second one had a moderate level of difficulty. The third question was a hard one, especially with less time allotted to it.                        </p>
                    </div>
                    <br />
                    <div>
                        <b>3. How many students were shortlisted after each round?</b>
                        <p>
                            After Aptitude: 22 <br />
                            After 1st Technical Round: 13 <br />
                            Final Selections: 11
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>4. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            A minimum of 7.5 CGPA was the criterion to be eligible to appear for the aptitude test.                        </p>
                    </div>

                    <br />
                    <div>
                        <b>5. How many rounds were there for the company, and can you elaborate on each round in brief?</b>
                        <p>
                            As I mentioned, there were 3 rounds.
                            <br /><br />First Round: This was purely a technical round. The interviewer deliberately started off with a few unsettling questions (I got to know from my peers that they had faced similar opening questions), where he asked me about my understanding of Cloud Computing and AI. Although I had not mentioned these topics in my resume, he asked these questions to know about my interpretation. He followed with few real-life scenarios and asked which data structures would be suitable to represent those cases. A basic SQL query was asked regarding finding the count of unique fruits in a table. He asked me what my approach would be to solve one DSA question, which was to find the count of unique words in a text file with a separate count for each line (I used hashmaps, actual code was not asked). A few questions were asked on sorting complexities and the final question was about Synchronous vs Asynchronous data transfer from Data Communications subject.
                            <br /><br />Second Round: This round was honestly where I was very nervous, because we had assumed this would be a technical round again, but it turned out to be a mixed bag of questions, with the senior manager asking technical as well as HR questions. Questions were majorly on projects and the technologies mentioned in the resume. The interviewer asked what was my favourite subject among the ones I had mentioned on my resume, and questions were mostly focused on what you answered. The interview felt like a good conversation, so it was easy to ask questions by the end of the interview and also seek feedback.
                            <br /><br />Third Round: This was an HR interview round. For me, this was my best round out of the three, as I felt confident in my answers. It started with basic questions regarding family background, educational background, how did I come to COEP, etc. The interviewer asked about which other banks (Citi,UBS,Deutsche) came on-campus for hiring interns and what I knew about Wells Fargo. Then he gave me one final question: “If you’re the campus ambassador for Wells Fargo at COEP, what steps would you take to make sure everyone knows about Wells Fargo here?” He gave me 3 minutes to prepare my answer. I had prepared for this question well, so I felt my answer had a good structure and rationale. Overall, this was a relaxed round after the two stressful interviews we had.

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)</b>
                        <p>
                            Questions were mostly based on DSA, OOP, DBMS and CN. OS questions were mostly asked to those who had mentioned it as a subject in their resume.                         </p>
                    </div>
                    <br />
                    <div>
                        <b>7. Some good resources you used in your internship prep?</b>
                        <p>
                            GFG Last Minute Notes for OOP, DBMS and CN. Love Babbar’s CodeHelp has a good playlist for DSA, and one shot videos for DBMS and OS revision as well.                        </p>
                    </div>
                    <br />
                    <div>
                        <b>8. Some tips to crack coding questions?</b>
                        <p>
                            <br /><br />For the online coding round, it is ideal if you’re familiar with online coding platforms, where you may be asked to write full programs and not just one function. Weekly CodeChef/Codeforces contests help to practise solving questions in a time-based environment.
                            <br /><br /> the technical interview round, before starting to solve the coding question, take your time in understanding what is asked, ask for clarifications if you are unsure about something. Prefer writing down what’s the given information and be vocal when you are solving the question. A lot of times, the interviewers don’t expect a perfect code but are more concerned with the approach that you follow to find the solution.
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>9. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
                        <p>
                            A lot of questions were focused on not necessarily the projects but the technologies used in the projects.
                             Not a lot of questions were asked on clubs and extracurricular activities, except for the HR round, where the interviewer asked me about my hobbies, my role in TEDxCOEP, why did I like participating in MUNs and what have I learnt so far through them.
                              (I had mentioned MUNs as one of my extracurricular activities and TEDx as one of the clubs I was a part of)                         </p>
                    </div>
                    <br />
                    <div>
                        <b>10. Is there something specific that you would want to tell people who try for the same company?</b>
                        <p>
                        Have confidence in yourself, try to remain calm during interviews, be polite and honest when you are not sure/aware about the answers rather than making a half-hearted attempt at answering the question, avoid complicating things.                         </p>
                    </div>
                    <br />
                    <div>
                        <b>Final Tip for Juniors:</b>
                        <p>
                        The internship cycle is an experience, where you will feel nervous and under confident at times, especially looking at your peers.
                        <br />The key is to keep going and learn from each other, rather than looking at them as your competitors. My advice is to not run after or be intimidated by the number of questions someone solves on Leetcode/GFG, their ratings on Codechef or their POTD streak.
                        <br />You don’t need to solve 300-400+ questions on Leetcode, you just need a decent number of questions under your belt until you feel confident in your ability to solve questions on popular topics.                         </p>
                    </div>
                    <br />
                    <div>
                        <b>contact details</b>
                        <p>
                            Email: <a href="mailto:inamdaras22.comp@coeptech.ac.in">inamdaras22.comp@coeptech.ac.in</a>
                        </p>
                    </div>


                </div>
            </section>
        </div>
    );
};

export default aashay_inamdar;
