import React from "react";

const yash_hodlur = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Yash Hodlur
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            1 Technical and 1 HR round

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            7+ CGPA  and No backlogs
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            Technical Aptitude included DBMS,OS,DSA ,then the Logical Aptitude round included maths,pattern recognition and graph analysis and there was 1 programming question (Different Questions were present set wise, Programming Q difficulty ranged from leetcode easy to medium)
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            Wait time was 1 day between being shortlisted and the interview  process
                            <br />During this time I prepared DSA and went through all my projects mentioned on my resume

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        2 rounds Technical and HR
                        <br />Technical Round:For me it mostly included project approach and explanation with some DSA questions on them.
                        <br />HR Round: I was asked about my hobbies and what are my expectations from the internship. Both the interviews were of about 30-45 mins

                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        Projects and DSA
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        25 students after Aptitude Round, 4 students selected after both Technical and HR round
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        Leetcode, Striver sheet, GFG cheat sheets and Neetcode
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Daily Practice on platforms like Leetcode and GFG,
                            Finishing the entire Striver Sheet
                            Watching NeetCode for Problem approaches and optimized solutions

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Almost 80% of the questions asked to me were from the above categories                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Projects, Projects and Projects:
                            <br />Make sure you do many useful projects from different fields ,for example don’t just restrict yourself to web development try AI/ML,Game development ,etc.

                            <br /><br />Always be calm:
                            <br />During your interviews always keep a calm and cool head.

                            <br /><br />Extra-curriculars:
                            <br />The company judges a lot on personality so do have extra curricular activities and hobbies.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Focus on Projects,Keep Calm and Don’t stress even if you do fumble some questions the outcome is always for the best.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:hodlurys22.comp@coeptech.ac.in">
                        hodlurys22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default yash_hodlur;
