import React from "react";

const arnav_gavde = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>Arnav Gavde</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>WellsFargo</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							Total of 4 rounds (including aptitude) <br />
							1. Online Aptitude
							<br />
							2. 1st online technical interview
							<br />
							3. 2nd offline technical interview
							<br />
							4. 3rd offline HR interview
						</p>
					</p>
					<p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
							In the 1st online technical interview the interviewer was mostly
							focused on analysing the basic technical knowledge skills like he
							was asking commonly asked interview questions on topics like DBMS,
							OOPS and OS. There was also a DSA problem that he gave me to solve
							based on my project. So keep your basics strong.
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							<b>Proctoring</b>: This online aptitude round took place on campus
							in college in one of the ACs where all 3rd yr students sat and
							were proctored. The test was on SHL platform and was proctored by
							camera and no tab switching was allowed. There were also 2-3
							company representatives in the class who were physically present
							to monitor us.
							<br />
							<b>Sections</b>: It consisted of 3 sections: English proficiency,
							business/finance-related and coding. The total duration of the
							test was 100 mins with each section having a separate time limit.
							There were rules like: You can’t switch sections and you can’t
							revisit a question once attempted (so you only get 1 chance to
							look at the question). So mentally prepare for these kinda rules.
							<br />
							<b>English proficiency section</b>: This section was the easiest
							of all the sections. It had around 12 questions and the time limit
							was 25 mins. It consisted of questions like spotting the error in
							a part of a sentence, arranging parts of sentences in correct
							order, filling in the blanks with correct words, etc. These types
							of questions can be practised using indiabix.com under the verbal
							ability section.
							<br />
							<b>Business / Finance related section</b>: This section was time
							consuming, lengthy and somewhat hard. It had around 15 questions
							and the time limit was 25 mins. The questions were based on
							profit-loss, tabular data of sales, graphical data of sales,
							paragraph questions, maths, basic candle-stick meaning, etc. The
							questions based on candle sticks weren’t about patterns but about
							the basics of a candle stick like what does the wick, body and
							colour signify and basic questions based on maths on the same.
							These types of questions can be practised using indiabix.com too
							under the data interpretation and the arithmetic aptitude section.
						</p>
						<b>Coding section</b>: Now this section had 2 subsections:
						<ul
							style={{
								paddingLeft: "1rem",
							}}
						>
							<li>
								The first subsection was timed for 40 mins and there were 2
								pretty simple coding questions:
								<ol>
									<li>
										The first question was: There is an array of n integers and
										k is given. Your task is to sort the first k elements in
										ascending and the next k in descending order.
									</li>
									<li>
										Unfortunately I don't remember the 2nd question Almost
										everyone was able to do these 2 questions
									</li>
								</ol>
							</li>
							<li>
								The second subsection was timed for only 10 mins and there was 1
								hard question:
								<ol>
									<li>
										The question’s name was Cryptoarithmetic. There were 3
										strings given and we had to satisfy this equation: str1 +
										str2 = str3 such that each char of all the strings denoted a
										no. from 0-9. Eg: SEND + MORE = MONEY and each char like
										S,E,N, etc. can be assigned random numbers like S:3, E:8,
										N:2, etc. such that when the equation is written in a
										numerical way, it satisfies the given equation. This was a
										tough question and almost nobody was able to solve it.
									</li>
								</ol>
							</li>
						</ul>
						{/* Uncomment following if list causes problems */}
						{/*
                        • The first subsection was timed for 40 mins and there were 2 pretty simple coding questions: <br />
                        1. The first question was: There is an array of n integers and k is given. Your task is to sort the first k elements in ascending and the next k in descending order. <br />
                        2. Unfortunately I don't remember the 2nd question Almost everyone was able to do these 2 questions <br />
                        • The second subsection was timed for only 10 mins and there was 1 hard question: <br />
                        1. The question’s name was Cryptoarithmetic. There were 3 strings given and we had to satisfy this equation: str1 + str2 = str3 such that each char of all the strings denoted a no. from 0-9. Eg: SEND + MORE = MONEY and each char like S,E,N, etc. can be assigned random numbers like S:3, E:8, N:2, etc. such that when the equation is written in a numerical way, it satisfies the given equation. This was a tough question and almost nobody was able to solve it. <br />
                        */}
						To conclude, the game changer was the Business / Finance section.
						After OA only 22 out of 127 students were selected for the next
						round.
					</p>
					<p>
						<b>
							4. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							We were informed 1 day prior so there wasn't much time after being
							shortlisted to prepare and cover everything. So I mostly referred
							to commonly asked interview questions on gfg and interview bit and
							then revised things that I felt I was weak in. During this time I
							would suggest using the pareto principle (80/20 rule) to your
							advantage i.e., focus on the 20% which will get you the 80%
							results and then focus on the weak topics. These 20% valuable
							topics could also be found using past experiences, so read as many
							as you can.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							5. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
						<p>
							<b>1st technical round:</b>
							{" "}
							This round was online on teams but we were called on campus to be
							present for it using our laptops. Theoretical questions were asked
							in this round. They might ask you your favourite subject and then
							proceed with it first so you can strengthen one subject which you
							like. Below are some questions i was asked:
							<br />
							• Basics: Introduction, Explain to me one of your projects.
							<br />
							• OS: Deadlocks in OS, How to prevent Deadlocks? (Use mutex), etc.
							<br />
							• DBMS: What are functional dependencies? What's normalisation?
							What are normal forms? Describe the 1nf, 2nf, 3nf and bcnf normal
							forms, etc.
							<br />
							• OOPS: What's inheritance? What's abstraction? What’s
							polymorphism? etc.
							<br />
							• DSA: Code up LRU cache (you can look up striver’s video for
							this); This was basically followed up after I explained my project
							<br />
							No puzzles were asked to me
							<br />

							<b>2nd technical round:</b>
							{" "}
							This round was offline in the interview cabins of COEP main
							building. It was a mixture of technical and hr. Application based
							challenging questions were asked in this round.
							<br />
							<u>Technical:</u> It was fully based on my projects.
							<br />
							<u>Questions:</u> How did you get the idea to do this project? How
							did you plan to come up with the solution? What part of the
							project did you handle? Based on my academic resource hub project:
							explain project, gave a table and asked to normalise it using 3nf;
							what's left, right, natural, full, inner and outer join?
							Disadvantages of decomposition of tables and then performing
							joins, etc. Based on my CDN project: How does CDN load balance?
							Should the database be stored on cached servers? What should be
							stored in the LRU cache? etc.
							<br />
							<u>HR:</u> Basic family background; What do we get from you? What
							happens if you don't get this offer? Hobbies (choose hobbies which
							might be hidden strengths)? Clubs (might reflect more about you as
							a person)?
							<br />
							
							<b>HR round:</b>
							{" "}
							This was a dedicated HR round.
							<br />
							Questions: Hobbies (again, but this time in just a bit more
							detail) ? What’s your biggest setback and how did you recover from
							it? What’s your biggest achievement? ,etc.
							<br />
							Look up common HR questions and prepare for them. Also Make sure
							to ask a question at the end of each interview when they ask you
							“Do you have any questions for us ? ”. By asking this question you
							get the opportunity to leave a +ve impression by asking about the
							company’s work values / the project it's currently working on /
							your project related work in the company, etc. Make sure the
							question is unique and genuine. Eg: I asked about how CDNs are
							incorporated in the company.
							<br />
						</p>
					</p>
					<br />

					{/* Commenting out because no answer was found in doc */}
					{/* 
					<p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
					</p>
					<br />
                    */}
					<p>
						<b>7. How many students were shortlisted after each round? </b>
						<br />
						After Apti: 22 <br />
						Final selection: 11
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep? </b>
						<br />
						• DSA: Love babbar / Striver’s SDE sheet / Neetcode. (Choose any one
						and stick to it) <br />
						• OOPs: apna college (do java and c++), interviewbit, gfg <br />•
						DBMS and OS: Love babbar’s one shot / playlist, gfg, interviewbit{" "}
						<br />
						• CN: Gate smashers, interviewbit, gfg <br />
						• Commonly asked interview questions on gfg and interviewbit for all
						the core subjects (OOPs, DBMS, CN, OS) <br />
						• Maths, english and general aptitude: indiabix <br />
						• Wells fargo gfg archives:
						https://www.geeksforgeeks.org/tag/wells-fargo/ <br />
						• If you have very less time just do commonly asked questions and
						wells fargo archives <br />
					</p>
					<br />
					<p>
						<b> 9. Some tips to crack coding questions ?</b>
						<br />
						<p>
							• Choose language which you are familiar with OR choose language
							which has intuitive syntax (eg: python). This can reduce your
							thinking overload significantly. <br />
							• To crack coding questions you will need to recognise patterns in
							new questions based on old ones which comes with practice. <br />
							• If you have time you can follow sheets with bigger problems
							otherwise follow sheets with at least 150 problems. Don't miss out
							on advanced topics like DP, Backtracking, graphs, greedy, etc. as
							these are asked in aptitude. Revise questions regularly to not
							forget them. <br />
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							10. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
							Most of their focus is on the technical side of your resume
							(skills, projects, subjects) and less focus is given towards
							extracurricular.
							<br />
							Majority (80-90%) of the questions were asked by looking at my
							resume so be careful what you put on your resume and only put the
							things in which you have at least 80% confidence. Make sure they don’t
							catch you not knowing stuff which you have put on your resume as
							it makes a bad impression. Also if you don't know the answer of
							some question in the interview, don’t try to make up stuff, just
							say that you will try and learn it later.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							11. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
							I would suggest to read all the other experiences in csi ty
							diaries (since each one of us were asked different kinds of
							questions) and go through this link of gfg without fail which
							contains archives of people who previously interviewed at Wells
							Fargo:
							<a href="https://www.geeksforgeeks.org/tag/wells-fargo/">
								https://www.geeksforgeeks.org/tag/wells-fargo/.
							</a>{" "}
							This proved to be a literal gem for me as it helped me a lot
							during my 1st technical online round.
						</p>
					</p>
					<br />
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>
							Being calm and confident is the key in interviews. Even if you
							don't get some questions right doesn't mean it's the end of the
							world. Just give in your 100% and be yourself. <br />
							Overall, be persistent and don't lose hope. It's a fact that you
							will face failures. Sometimes you might not pass aptitudes and
							sometimes you may fail interviews and there is no need to worry
							about this as this is a part of the process. Don't get demotivated
							by seeing your friends getting offers before you as everyone has a
							different timeline. <br />
							“Karma karte raho, fal ki aasha chhod do”
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:gavdear22.comp@coeptech.ac.in">
							gavdear22.comp@coeptech.ac.in
						</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default arnav_gavde;
