import React from "react";

const smruti_pachpute = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Smruti Pachpute
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>UBS</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                        Round-0: Aptitude Test
                        <br/>Round-1: Technical Interview
                        <br/>Round-2: HR Interview
                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                        The primary criterion was a minimum CGPA of 7.00.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        The Aptitude Test was conducted on HackerRank. <br/>
                        It consisted of multiple-choice questions (MCQs) covering Object-Oriented Programming (OOPs), Operating Systems (OS), SQL queries, and IQ-based questions. 
                        Additionally, there was one easy coding question.
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br/>
                        <p>
                        The Aptitude Round was conducted on 29th July, and the shortlist was announced the same night. Interviews were held the next day, 30th July. 
                        <br/>During this time, I focused on revising fundamental concepts of Object-Oriented Programming (OOP) and SQL.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Round-1: Technical Interview <br/>
                        The Technical Interview primarily tested basic concepts. It started with an explanation of my projects, 
                        followed by a few related questions. I was then asked to write a C++ code to swap two variables without using a third variable, which boosted my confidence. 
                        Next, they asked me to write a SQL query and posed questions about the compilation process, OOP principles, and Data Structures. The interview lasted approximately 20 minutes.
                        <br/>Round-2: HR Interview <br/>
                        The HR Interview was smooth and conversational. The HR Manager asked questions about me and my family background. 
                        She also gave me idea about how the internship would be (which gave me a confidence that I was in :) )
                     </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        The primary focus was on the basics of OOP, DBMS and the basics of Programming.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        The initial shortlisting was based on CGPA, with a minimum criterion of 7.00. After the Aptitude Round, 30 students were shortlisted. 
                        Candidates who cleared the Technical Interview were immediately sent for the HR Interview. Finally, 5 students were selected for the internship.
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        GeeksforGeeks (GFG) and Javatpoint for DBMS and OOP concepts.
                        <br/>Basic knowledge of Computer Networks and Operating Systems is useful for the Aptitude Round.
                        <br/>Have a solid understanding of all subjects covered up to the 4th semester.
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Practice a variety of coding problems; there is no shortcut.
                        <br/>Prefer Java or C++ for coding but also understand Python functions, as they can simplify some problems.
                        <br/>Focus on optimizing your solutions to pass most test cases.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        There was a detailed discussion about my projects during the Technical Interview. 
                        Extracurricular activities mentioned in your resume can be helpful during the HR Interview.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Your knowledge and skills are crucial, but your presentation, personality, and confidence also play a significant role.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Stay consistent in your preparation. There will be ups and downs during these internship days, but remain strong and focused. 
                        Practice coding regularly, revise core subjects, and prepare your projects thoroughly. Confidence, clarity in communication, and a well-prepared resume can significantly boost your chances. 
                        Good luck!
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:pachputesj22.comp@coeptech.ac.in">
                        pachputesj22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default smruti_pachpute;
