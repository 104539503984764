import React from "react";

const amruni_mehta = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div className="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Amruni Mehta
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            : 1 Aptitude Test + 2 Technical Rounds + HR
                        </p>
                    </div>
                    <div>
                        <b>2. What was the structure of the Aptitude Round?</b>
                        <p>
                            The aptitude test consisted of three sections. Section A was English, Section B was statistics and basic financial graph related questions, and Section C was coding (3 questions).
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>3. How many students were shortlisted after each round?</b>
                        <p>
                            After Aptitude: 22 <br />
                            After 1st Technical Round: 13 <br />
                            Final Selections: 11
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>4. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            The shortlisting in the aptitude test was mostly done based on the finance section as the coding questions were easy-med level, and many students could solve at least 2 out of 3 completely. Then passing of tech 1 round was based on how well you can answer the coding questions asked during the interview (approach) and the knowledge of DSA, OOPS, and concepts written in your resume.
                        </p>
                    </div>
                    
                    <br />
                    <div>
                        <b>5. How many rounds were there for the company, and can you elaborate on each round in brief?</b>
                        <p>
                            1 Aptitude Test + 2 Technical Rounds + HR <br />
                            1st Tech Round (Online) - Based on prior knowledge involving concepts of DSA, explaining the approach to DSA coding questions involving queue, stack, and other data structures and their applications, OOPS concepts, basic CN, DBMS basic queries. <br />
                            2nd Tech round (offline) - Detailed questioning and discussion on all projects mentioned in the resume and writing code for DSA questions. Involved a few HR questions (Why should we hire you, Why do you want to join Wells Fargo?).
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)</b>
                        <p>
                            Focus on all data structures (queue, stack, Tree, implementing one structure using other ones), and their real-life applications, general interview puzzles (all available on GFG), basic DBMS Queries, Networking concepts (they asked us if you know the basics of cloud computing, IoT, OS, ML), and DP. Have detailed knowledge about the projects and libraries used and mentioned in your resume. In HR, prepare for typical HR questions.
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>7. Some good resources you used in your internship prep?</b>
                        <p>
                            Love Babbar, may refer to GFG for interview puzzles, DP and backtracking playlist. You can also solve the 450 DSA questions and the Striver sheet.
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>8. Some tips to crack coding questions?</b>
                        <p>
                            Practice Leetcode easy-med questions on arrays and strings.
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>9. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
                        <p>
                            Tech 2 was entirely resume-based, asking about every tech and non-tech detail mentioned in the resume.
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>10. Is there something specific that you would want to tell people who try for the same company?</b>
                        <p>
                            Ensure your basics in DSA and OOPS are strong. Focus on explaining your problem-solving approach, even if you don�t arrive at the correct solution. Be prepared to discuss your projects in detail, including real-life implementation and possible improvements. Practice typical HR questions for the final round.
                        </p>
                    </div>
                    <br />
		     <div>
                        <b>Final Tip for Juniors:</b>
                        <p>
                            Keep calm and focus on one interview at a time. Give your answers confidently.
                        </p>
                    </div>
                    <br />
                    <div>
                        <b>contact details</b>
                        <p>
                            Email: <a href="mailto:mehtaar22.comp@coeptech.ac.in">mehtaar22.comp@coeptech.ac.in</a>
                        </p>
                    </div>
                    
                    
                </div>
            </section>
        </div>
    );
};

export default amruni_mehta;
